<template>
  <div class="damagereport">
    <header-small>
      <span>
        {{ userName }}<br>
        <template v-if="damagereport.create_date">
          {{ createDate }}
        </template>
      </span>
      <img class="profile" src="@/assets/icons/profile-image.png" alt="">
    </header-small>
    <transition name="fade">
      <v-container v-if="$route.params.id">
        <router-view />
      </v-container>
    </transition>
  </div>
</template>

<script>
import * as moment from 'moment';
import { mapGetters } from 'vuex';

import HeaderSmall from '@/components/HeaderSmall.vue';

import httpResourcesMixin from '@/mixins/httpResources';

export default {
  name: 'DamageReport',
  components: {
    HeaderSmall,
  },

  props: {

  },

  data() {
    return {
      damagereport: {},
    };
  },

  mounted() {
    if (!this.$route.params.id) {
      this.createDamagereport();
    }
    this.checkFeatureFlags();
  },

  methods: {
    createDamagereport() {
      this.httpResources.report.save()
        .then((response) => {
          if (response.body.id) {
            this.$router.replace({
              path: `/damagereport/${response.body.id}`,
            });
          }
        });
    },
    checkFeatureFlags() {
      this.httpResources.features.get()
        .then((response) => {
          if (response.body) {
            this.$store.commit('featureFlags/setFeatureFlags', response.body);
          }
        });
    },
  },

  computed: {
    ...mapGetters({
      exp: 'user/exp',
      userName: 'user/name',
    }),

    createDate() {
      return moment(this.damagereport.create_date).format('DD.MM.YYYY');
    },
  },

  mixins: [
    httpResourcesMixin,
  ],
};
</script>

<style scoped lang="scss">
.profile {
  max-width: 42px;

  display: inline-block;
  vertical-align: middle;
}
</style>

<template>
  <div class="header  header--small">
    <v-container>
      <v-layout>
        <v-flex xs-6>
          <router-link :to="{ path: '/home' }">
            <img src="../assets/wisag_logo.png" alt="Wisag"><br>
            <span>KFZ-Schadensaufnahme</span>
          </router-link>
        </v-flex>
        <v-flex
          class="content-slot"
          xs-6
        >
          <slot></slot>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HeaderSmall',
};
</script>

<style scoped lang="scss">
@import './../scss/settings.scss';

.layout {
  align-items: center;
}

.content-slot {
  text-align: right;
}

span {
  margin-right: 10px;

  color: $clr-black-xxlight;

  display: inline-block;
  vertical-align: middle;
}
</style>
